// Generated by Framer (be6943a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ZDPu1xcvo", "ZX_tHrhz3", "Z5C3DxX1G", "ZgZ1kKiqC", "nkTlfwx7B", "REOvu3kes"];

const serializationHash = "framer-Bp6Sg"

const variantClassNames = {nkTlfwx7B: "framer-v-1pko6yg", REOvu3kes: "framer-v-18n69dt", Z5C3DxX1G: "framer-v-tfoccb", ZDPu1xcvo: "framer-v-1cjy5op", ZgZ1kKiqC: "framer-v-oihub3", ZX_tHrhz3: "framer-v-12s44nu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "REOvu3kes", "Image 16:9": "ZgZ1kKiqC", "Image 3:2": "Z5C3DxX1G", "Image 4:3": "ZX_tHrhz3", "Image 4:5": "nkTlfwx7B", Default: "ZDPu1xcvo"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZDPu1xcvo", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ZDPu1xcvo", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1cjy5op", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"ZDPu1xcvo"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({nkTlfwx7B: {"data-framer-name": "Image 4:5"}, REOvu3kes: {"data-framer-name": "Image 1:1"}, Z5C3DxX1G: {"data-framer-name": "Image 3:2"}, ZgZ1kKiqC: {"data-framer-name": "Image 16:9"}, ZX_tHrhz3: {"data-framer-name": "Image 4:3"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Bp6Sg.framer-1i5okfc, .framer-Bp6Sg .framer-1i5okfc { display: block; }", ".framer-Bp6Sg.framer-1cjy5op { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-Bp6Sg.framer-v-12s44nu.framer-1cjy5op { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-Bp6Sg.framer-v-tfoccb.framer-1cjy5op { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-Bp6Sg.framer-v-oihub3.framer-1cjy5op { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-Bp6Sg.framer-v-1pko6yg.framer-1cjy5op { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-Bp6Sg.framer-v-18n69dt.framer-1cjy5op { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ZX_tHrhz3":{"layout":["fixed","fixed"]},"Z5C3DxX1G":{"layout":["fixed","fixed"]},"ZgZ1kKiqC":{"layout":["fixed","fixed"]},"nkTlfwx7B":{"layout":["fixed","fixed"]},"REOvu3kes":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPOdAasa9t: React.ComponentType<Props> = withCSS(Component, css, "framer-Bp6Sg") as typeof Component;
export default FramerPOdAasa9t;

FramerPOdAasa9t.displayName = "Image / Aspect Ratio Copy 8";

FramerPOdAasa9t.defaultProps = {height: 200, width: 200};

addPropertyControls(FramerPOdAasa9t, {variant: {options: ["ZDPu1xcvo", "ZX_tHrhz3", "Z5C3DxX1G", "ZgZ1kKiqC", "nkTlfwx7B", "REOvu3kes"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramerPOdAasa9t, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})